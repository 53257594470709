@import '~bootstrap/dist/css/bootstrap.min.css';
@import './css/common.css';
@import './css/buttons.css';
@import './css/navbar.css';
@import './css/sidebar.css';
@import './css/blockSidebar.css';
@import './css/flowy.css';

:root {
	--paddingLeft: 84px;
	--sbWidth: 88px;
	--navHeight: 44px;
	--navHeightDouble: 88px;
	--navInactiveColor: #bcbcbc;
	--barBgColor: #fbfbfb;
}

body {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
}

* {
	outline: none !important;
}
button::-moz-focus-outer,
button::-moz-focus-inner,
button:active,
button:focus,
button {
	border: 0;
	outline: none !important;
}

.container-fluid {
	padding-left: 0;
	height: 100%;
}

.wrapper {
	display: flex;
	width: 100%;
}

#content {
	margin-left: var(--sbWidth);
	width: 100%;
	min-height: 100vh;
}

#page-body {
	padding-top: 28px;
	padding-left: var(--paddingLeft);
	padding-right: var(--paddingLeft);
	margin-top: var(--navHeight);
	height: 100%;
}

/*for Arch page ^^^^ add here*/
.nopadding {
	padding: 0 !important;
	/*margin-top: var(--navHeight);*/
	max-height: calc(100vh - var(--navHeightDouble));
	overflow: hidden;
}

.ai-button {
	width: 28px;
	background-color: #f3f5fb;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s; /* Плавный переход фона */
}

.ai-button:hover {
	background-color: #e0e4eb; /* Цвет фона при наведении */
}

.custom-offcanvas {
	width: 1200px; /* Установите нужную ширину */
}
/* styles.css */
.editable-textarea {
	font-size: 16px;
	font-weight: light;
	margin-top: 4px;
	padding: 8px;
	border: 1px solid transparent;
	border-radius: 4px;
	width: 100%;
	resize: none; /* Отключаем возможность изменения размера */
	box-sizing: border-box;
	transition: border-color 0.3s ease, background-color 0.3s ease; /* Плавный переход для рамки и фона */
}

.editable-textarea:focus {
	border-color: #007bff; /* Цвет рамки при фокусе */
	outline: none;
	background-color: #e9f5ff; /* Цвет фона при фокусе */
}

.editable-textarea::placeholder {
	color: #aaa;
	transition: opacity 0.3s ease; /* Плавный переход для placeholder */
}

.select-no-border {
  border: none;
  background: none;
  color: #000; /* Цвет текста, чтобы он выглядел как ссылка */
	padding: 0 0 0 10px !important;

  font-size: inherit;
  cursor: pointer; /* Курсор указателя, чтобы показать, что это интерактивный элемент */
  appearance: none; /* Убирает стандартный стиль для разных браузеров */
  outline: none; /* Убирает стандартное выделение */
}

.select-no-border:focus {
  outline: none !important; /* Убирает фокусную рамку */
  box-shadow: none !important; /* Убирает тень при фокусе */
}

/* Включение глобального сброса стилей для всех select элементов */
select {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table-row:hover {
  background-color: #f0f0f0; /* Цвет при наведении */
}