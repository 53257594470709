.nav {
    font-size: 15px;
    /*line-height: 20px;*/
    position: fixed;
    height: var(--navHeight);
    width: 100%;
    padding-left: var(--paddingLeft);
    background: var(--barBgColor);
    /*margin-left: -16px;*/
}

.nav-link.active > p {
    border-bottom: 1px solid #000;
    color: #000
}

.nav-link {
    cursor: pointer;
    color: var(--navInactiveColor);
    transition: 0.3s ease;
}

.nav-link:hover {
    color: #000;
    transition: 0.3s ease;
}

#subNav {
    top: var(--navHeight) !important;
    display: none;
    border-top: 1px solid #f0f0f0;
}

.nav li:first-child > span {
    padding-left: 0;
}
