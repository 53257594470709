#sidebar {
    width: var(--sbWidth);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: var(--barBgColor);
    color: #000;
    overflow: hidden;
    border-right: 1px solid #f0f0f0;
    padding: 16px 24px 84px 24px;
    overflow-y: scroll;
    scrollbar-width: none;
}
#sidebar::-webkit-scrollbar {
    display: none;
}

#sidebar .sbEl {
    padding: 0;
    background: #ECEBFB;
    border-radius: 8px;
    text-align: center;
    font-size: 15px;
    text-transform: capitalize;
    color: #6A4EF4;
    width: 40px;
    height: 40px;
    line-height: 36px;
    cursor: pointer;
    margin-bottom: 16px;
    transition: 0.3s ease;
}

.sb-active {
    border: 2px solid #6A4EF4;
}

#sidebar .sbAdd {
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 32px;
    color: var(--navInactiveColor);
    cursor: pointer;
    margin-top: -10px;
    transition: 0.3s ease;
}

#sidebar .sbAdd:hover {
    color: #000;
    transition: 0.3s ease;
}

#sidebar .cog {
    margin: 0;
    padding: 16px 24px 0 24px;
    position: fixed;
    /*bottom: 24px;*/
    bottom: 0;
    left: 0;
    right: 0;
    width: var(--sbWidth);
    /*height: 44px;*/
    height: 84px;
    background-color: var(--barBgColor);
    /*border: 1px solid red;*/
}

#sidebar .cog i {
    width: 40px;
    height: 40px;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #6a4ef4;
    background-color: #ecebfb;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s ease;
}
#sidebar .cog i:hover, #sidebar .sbEl:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.14),0 1px 7px 0 rgba(0,0,0,.12),0 3px 1px -1px rgba(0,0,0,.2);
    transition: 0.3s ease;
}

.cog-active {
    color: #ecebfb !important;
    background-color: #6a4ef4 !important;
}
